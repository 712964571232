import React from 'react';
import jwt_decode from "jwt-decode";
import { Redirect, Route } from "react-router-dom";
function PrivateRoute({ children, ...rest }) {
  const expiredToken = (token) => {
    if (!token) return false;
    if (jwt_decode(token['access_token']).exp < Date.now() / 1000) {
      localStorage.clear();
      return false;
    }
    return true;
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        expiredToken(JSON.parse(localStorage.getItem('token') ?? null)) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
export default PrivateRoute;
